
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { useRouter } from "vue-router";
import Multiselect from "@vueform/multiselect";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { searchPaymentPlans } from "@/api/paymentPlan.api";
import { getOrganization } from "@/core/services/JwtService";
import { useStore } from "vuex";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

export interface Data {
  isLoaded: boolean;
  items: any[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    datesList: { keyword: number; name: string }[];
    installmentStatuses: { code: string; description: string }[];
  };
  filter: {
    dateType: number;
    startDate: Date | string | null;
    endDate: Date | string | null;
  };
}

export default defineComponent({
  components: { PaginationUi, Multiselect, DateFloatComponent },
  name: "PatientPaymentPlans",
  props: ["patientId"],
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const orderList = [
      {
        name: "Payment Plan",
        key: "title",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Create Date",
        key: "createdOn",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payment Plan Amount",
        key: "amount",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payment Plan Balance",
        key: "balance",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Balance Due Patient",
        key: "patient.patientAccount.outstandingBalancePatient",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Balance Due Insurance",
        key: "patient.patientAccount.outstandingBalanceInsurance",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Last Installment Date",
        key: "lastInstallmentDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Next Installment Date",
        key: "nextInstallmentDate",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      keyword: "",
      pagination: {
        currentPage: 1,
        totalPages: 1,
        totalCount: 0,
        pageSize: 10,
      },
      orderBy: "createdOn",
      isDecr: true,
      panel: {},
      IsAdvancedOrderBy: false,
      dict: {
        datesList: [
          { keyword: 0, name: "Created Date" },
          { keyword: 1, name: "Last Installment Date" },
          { keyword: 2, name: "Next Installment Date" },
        ],
        installmentStatuses: [],
      },
      filter: {
        dateType: 0,
        startDate: null,
        endDate: null,
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.dict.installmentStatuses = store.getters.allInstallmetStatuses;

      await getAll();
      data.isLoaded = true;
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "createdOn";
      data.IsAdvancedOrderBy = false;
      data.isDecr = true;
      data.filter = {
        dateType: 0,
        startDate: null,
        endDate: null,
      };

      await getAll();
    }

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        globalSearch: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        DateType: data.filter.dateType,
        StartDate: data.filter.startDate,
        EndDate: data.filter.endDate,
        PatientId: props.patientId,
      };

      const res = await searchPaymentPlans(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function selectPaymentPlan(item) {
      router.push({ path: "/paymentPlan/" + item.id });
    }

    function postPayment(installment) {
      router.push({
        path: "/Payments/patientPayment",
        query: { type: 0, installmentId: installment.id },
      });
    }

    return {
      clearSearch,
      data,
      orderList,
      organizationId,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      selectPaymentPlan,
      postPayment,
    };
  },
});
